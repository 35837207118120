import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "../../../hooks";
import { MyEarningsPartnerEnum } from "../../../models";
import {
  LogoAzul_84_51,
  LogoIturan_99_51,
  LogoPorto_110_35,
  LogoSupervisao_120_52,
  LogoYelum_164_89,
  LogoZapay_108_46,
} from "../../assets";
import { MyEargingsPartnerCardAtom } from "../../atoms";
import {
  MyEarningsAzulOrganism,
  MyEarningsIturanOrganism,
  MyEarningsYelumOrganism,
  MyEarningsPortoOrganism,
  MyEarningsSupervisaoOrganism,
  MyEarningsZapayOrganism,
} from "../../organisms";
import {
  ContentWrapper,
  MyEarningsInitialDescriptionText,
  PageTitle,
  PartnersSelectionDiv,
} from "./my-earnings.page.style";

export const MyEarningsPage = () => {
  const { t } = useTranslation();

  const { setActualPageContext } = useSessionContext();

  useEffect(() => {
    setActualPageContext("MYGAINS");
    // eslint-disable-next-line
  }, []);

  const [selectedPartner, setSelectedPartner] = useState<MyEarningsPartnerEnum>(
    MyEarningsPartnerEnum.Ituran
  );

  const partners = useMemo(
    () => [
      {
        id: MyEarningsPartnerEnum.Ituran,
        logo: LogoIturan_99_51,
        iconAltText: "Logo Ituran",
      },
      {
        id: MyEarningsPartnerEnum.Yelum,
        logo: LogoYelum_164_89,
        iconAltText: "Logo Yelum Seguradora",
      },
      {
        id: MyEarningsPartnerEnum.Azul,
        logo: LogoAzul_84_51,
        iconAltText: "Logo Azul Seguros",
      },
      {
        id: MyEarningsPartnerEnum.Porto,
        logo: LogoPorto_110_35,
        iconAltText: "Logo Porto Seguro",
      },
      {
        id: MyEarningsPartnerEnum.Supervisao,
        logo: LogoSupervisao_120_52,
        iconAltText: "Logo Supervisão",
      },
      {
        id: MyEarningsPartnerEnum.Zapay,
        logo: LogoZapay_108_46,
        iconAltText: "Logo Zapay",
      },
    ],
    []
  );

  const getPartnerEarningsDetail = (id?: MyEarningsPartnerEnum) => {
    if (id === undefined)
      return (
        <MyEarningsInitialDescriptionText>
          {t("MyEarningsInitialDescription")}
        </MyEarningsInitialDescriptionText>
      );

    switch (id) {
      case MyEarningsPartnerEnum.Ituran:
        return <MyEarningsIturanOrganism />;
      case MyEarningsPartnerEnum.Yelum:
        return <MyEarningsYelumOrganism />;
      case MyEarningsPartnerEnum.Azul:
        return <MyEarningsAzulOrganism />;
      case MyEarningsPartnerEnum.Porto:
        return <MyEarningsPortoOrganism />;
      case MyEarningsPartnerEnum.Supervisao:
        return <MyEarningsSupervisaoOrganism />;
      case MyEarningsPartnerEnum.Zapay:
        return <MyEarningsZapayOrganism />;
    }
  };

  return (
    <>
      <ContentWrapper>
        <Container className={"mb-4"}>
          <Row className="mb-5">
            <Col>
              <PageTitle>{t("MyEarningsTitle")}</PageTitle>
            </Col>
          </Row>

          <Row className="my-5 pb-5">
            <Col>
              <PartnersSelectionDiv>
                {partners.map((m, i) => (
                  <MyEargingsPartnerCardAtom
                    key={`my-earnings-partner-${i}`}
                    selected={m.id === selectedPartner}
                    logo={m.logo}
                    onClick={() => setSelectedPartner(m.id)}
                    altText={m.iconAltText}
                  />
                ))}
              </PartnersSelectionDiv>
            </Col>
          </Row>

          <Row className="my-5">
            <Col>{getPartnerEarningsDetail(selectedPartner)}</Col>
          </Row>
        </Container>
      </ContentWrapper>
    </>
  );
};

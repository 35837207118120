import { CoxIcon, PermissionEnum } from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Scroll from "react-scroll";
import { useBuyerDelivery, useSessionContext } from "../../../hooks";
import { ICpvHomeBigCardData, ICpvHomeSmallCardData } from "../../../models";
import {
  AzulLogoHome,
  CircleArrowDown,
  CircleArrowUp,
  DtLogoHome,
  DtSimularHome,
  IturanLogoHome,
  KbbLogoHome,
  KbbPrecificarHome,
  ManheimAcessarHome,
  ManheimLogoHome,
  PortoSeguroLogoHome,
  Rota21LogoHome,
  SafraFinanceiraLogoHome,
  SuperVisaoLogoHome,
  YelumLogoHome,
} from "../../assets";
import {
  AllBanksCardAtom,
  RegistrationHomeCardAtom,
  RegistrationHomeSmallCardAtom,
} from "../../atoms";
import {
  BigCardsDiv,
  ButtonSrcollDiv,
  ContentWrapper,
  ExtraCardDiv,
  FixedHeightDiv,
  HeadLineDiv,
  LoadingContainer,
  LoadingText,
  ManheimDismiss,
  ManheimLink,
  ManheimWarn,
  ManheimWarnBold,
  Slide,
  Slider,
  Slides,
  SmallCardsDiv,
  Title,
  TranslucidBottomDiv,
  TranslucidTopDiv,
} from "./protected.page.style";

export const ProtectedPage = () => {
  const { t } = useTranslation();

  const { setActualPageContext, userContext, auth0UserContext } =
    useSessionContext();

  const { GetShowHeadline } = useBuyerDelivery();

  const RoutesHref = useMemo(() => {
    return process.env.REACT_APP_BUILD === "staging"
      ? "https://hml.cadastro.coxpravoce.com.br/#/routes-program"
      : process.env.REACT_APP_BUILD === "production"
      ? "https://cadastro.coxpravoce.com.br/#/routes-program"
      : "http://localhost:3000/#/routes-program";
  }, []);

  const [hasKbb, setHasKbb] = useState(false);
  const [hasManheim, setHasManheim] = useState(false);
  const [hasDealertrack, setHasDealertrack] = useState(false);
  const [hasInspectionPermission, setHasInspectionPermission] =
    useState<boolean>(false);
  const [hasInsurancePermission, setHasInsurancePermission] =
    useState<boolean>(false);
  const [hasTrackerPermission, setHasTrackerPermission] =
    useState<boolean>(false);
  const [showHeadline, setShowHeadline] = useState<boolean>(false);
  const [dismissHeadline, setDismissheadline] = useState<boolean>(false);

  useEffect(() => {
    setActualPageContext("HOME");
    const dismissHeadline = sessionStorage.getItem("dismiss-headline");
    setDismissheadline(!!dismissHeadline);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const appMetadata = auth0UserContext?.appMetadata as any;
    if (!!appMetadata) {
      const permissions = Object.keys(appMetadata)
        .filter((f) => /app:.+$/g.test(f) && appMetadata[f] === "True")
        .map((m) => m.match(/app:.+$/)?.toString());

      const permissionsDenied = Object.keys(appMetadata)
        .filter((f) => /app:.+$/g.test(f) && appMetadata[f] === "False")
        .map((m) => m.match(/app:.+$/)?.toString());

      setHasInspectionPermission(
        permissions.includes(PermissionEnum.ProductsInspection) ||
          !permissionsDenied.includes(PermissionEnum.ProductsInspection)
      );
      setHasInsurancePermission(
        permissions.includes(PermissionEnum.ProductsInsurance) ||
          !permissionsDenied.includes(PermissionEnum.ProductsInsurance)
      );
      setHasTrackerPermission(
        permissions.includes(PermissionEnum.ProductsTracker) ||
          !permissionsDenied.includes(PermissionEnum.ProductsTracker)
      );
      setHasKbb(
        permissions.includes(PermissionEnum.Kbb) ||
          !permissionsDenied.includes(PermissionEnum.Kbb)
      );
      setHasManheim(
        !!userContext?.isMaster &&
          (permissions.includes(PermissionEnum.Manheim) ||
            !permissionsDenied.includes(PermissionEnum.Manheim))
      );
      setHasDealertrack(
        permissions.includes(PermissionEnum.Dealertrack) ||
          !permissionsDenied.includes(PermissionEnum.Dealertrack)
      );
    }
  }, [auth0UserContext, userContext?.isMaster]);

  useEffect(() => {
    getShowHeadlineAsync();
    // eslint-disable-next-line
  }, [auth0UserContext, showHeadline]);

  const bigCardsData: ICpvHomeBigCardData[] = [
    {
      cardProlife: "kbb",
      cardBorderColor: "#1F3E74",
      logoSrc: KbbLogoHome,
      logoAltText: "Kbb Logo",
      cardText: t("WeOfferThePrice"),
      cardImgSrc: KbbPrecificarHome,
      cardImgAltText: "Kbb Precificar imagem",
      buttonText: t("PricingWithKBB"),
      onClick: () => {
        window.location.href = process.env.REACT_APP_C4U_KBB_URL ?? "";
      },
      buttonDisabled: !hasKbb,
      buttonProfile: "manheim",
      dataElement: "product-card-primary",
      dataProduct: "kbb",
    },
    {
      cardProlife: "dt",
      cardBorderColor: "#1F3E74",
      logoSrc: DtLogoHome,
      logoAltText: "Dealertrack Logo",
      cardText: t("UseItNow"),
      cardImgSrc: DtSimularHome,
      cardImgAltText: "Dealertrack Simular Imagem",
      buttonText: t("SimulateFinancing"),
      onClick: () => {
        window.location.href = process.env.REACT_APP_C4U_DT_URL ?? "";
      },
      buttonDisabled: !hasDealertrack,
      buttonProfile: "manheim",
      dataElement: "product-card-primary",
      dataProduct: "dt",
    },
    {
      cardProlife: "manheim",
      cardBorderColor: "#F3BC2A",
      logoSrc: ManheimLogoHome,
      logoAltText: "Manheim Logo",
      cardText: t("ComposeTheStock"),
      cardImgSrc: ManheimAcessarHome,
      cardImgAltText: "Manheim Acessar Imagem",
      buttonText: t("AccessTheOffers"),
      onClick: () => {
        window.location.href = process.env.REACT_APP_C4U_MANHEIM_URL ?? "";
      },
      buttonDisabled: !hasManheim,
      buttonProfile: "kbb",
      dataElement: "product-card-primary",
      dataProduct: "manheim",
    },
  ];
  const extraCardData = useMemo<ICpvHomeBigCardData>(
    () => ({
      cardProlife: "allbanks",
      cardBorderColor: "#005A9A",
      logoSrc: SafraFinanceiraLogoHome,
      logoAltText: "Safra Financeira",
      buttonProfile: "creditas",
      dataElement: "product-card-primary",
      dataProduct: "allbanks",
      buttonDisabled: !hasDealertrack,
      // className: "override-disabled-color",
    }),
    [hasDealertrack]
  );

  const smallCardsData = useMemo<ICpvHomeSmallCardData[]>(
    () => [
      {
        id: "card1",
        onClick: () => {
          hasInsurancePermission &&
            (window.location.href =
              process.env.REACT_APP_C4U_INSURANCE_URL ?? "");
        },
        backgroundColor: "#0065A2",
        logoSrc: PortoSeguroLogoHome,
        logoAltText: "Porto Seguro Logo",
        title: t("PortoSeguro"),
        description: t("PortoSeguroCarInsurance"),
        disabled: !hasInsurancePermission,
        dataElement: "product-card-secondary",
        dataProduct: "porto-seguro",
      },
      {
        id: "card2",
        onClick: () => {
          hasInsurancePermission &&
            (window.location.href =
              process.env.REACT_APP_C4U_INSURANCE_URL ?? "");
        },
        backgroundColor: "#FFD478",
        logoSrc: YelumLogoHome,
        logoAltText: "Yelum Seguradora Logo",
        title: t("Yelum"),
        description: t("YelumInsurance"),
        disabled: !hasInsurancePermission,
        dataElement: "product-card-secondary",
        dataProduct: "yelum-seguradora",
      },
      {
        id: "card3",
        onClick: () => {
          hasInsurancePermission &&
            (window.location.href =
              process.env.REACT_APP_C4U_INSURANCE_URL ?? "");
        },
        backgroundColor: "#1F3E74",
        logoSrc: AzulLogoHome,
        logoAltText: "Azul Seguros Logo",
        title: t("Azul"),
        description: t("AzulSegurosCarInsurance"),
        disabled: !hasInsurancePermission,
        dataElement: "product-card-secondary",
        dataProduct: "azul-seguros",
      },
      {
        id: "card4",
        onClick: () => {
          hasTrackerPermission &&
            (window.location.href =
              process.env.REACT_APP_C4U_TRACKER_URL ?? "");
        },
        backgroundColor: "#F3BC2A",
        logoSrc: IturanLogoHome,
        logoAltText: "Ituran Logo",
        title: t("Ituran"),
        description: t("IturanVehicleTrackers"),
        disabled: !hasTrackerPermission,
        dataElement: "product-card-secondary",
        dataProduct: "ituran",
      },
      {
        id: "card5",
        onClick: () => {
          hasInspectionPermission &&
            (window.location.href =
              process.env.REACT_APP_C4U_INSPECTION_URL ?? "");
        },
        backgroundColor: "rgba(168, 170, 172, 0.35)",
        logoSrc: SuperVisaoLogoHome,
        logoAltText: "SuperVisão Logo",
        title: t("Supervisao"),
        description: t("SupervisaoHireSurveys"),
        disabled: !hasInspectionPermission,
        dataElement: "product-card-secondary",
        dataProduct: "supervisao",
      },
      {
        id: "card6",
        onClick: () => {
          window.location.href = RoutesHref ?? "";
        },
        backgroundColor: "#3A5693",
        logoSrc: Rota21LogoHome,
        logoAltText: "Rota 21 Logo",
        title: t("RotaProgram"),
        description: t("RotaLoyaltyProgram"),
        dataElement: "product-card-secondary",
        dataProduct: "rota21",
      },
    ],
    [
      RoutesHref,
      hasInspectionPermission,
      hasInsurancePermission,
      hasTrackerPermission,
      t,
    ]
  );

  const scroll = Scroll.animateScroll;
  const propsScroll = {
    duration: 500,
    delay: 20,
    smooth: true,
    containerId: "button-scrollable-div",
  };

  const [isScrolledDown, setIsScrolledDown] = useState(false);

  const smallCardsContent = useMemo(
    () => (
      <SmallCardsDiv className="mt-md-5 mt-lg-0">
        {smallCardsData.map((m, i) => (
          <RegistrationHomeSmallCardAtom
            key={`small-card-${i}`}
            onClick={m.onClick}
            data={m}
            comingSoonText={t("ComingSoon")}
            dataElement={m.dataElement}
            dataProduct={m.dataProduct}
            dataPosition={i + 1}
          />
        ))}
      </SmallCardsDiv>
    ),
    [t, smallCardsData]
  );

  const getShowHeadlineAsync = useCallback(async () => {
    if (auth0UserContext !== undefined && showHeadline === undefined) {
      const response = await GetShowHeadline();
      console.log(response.showHeadline);
      setShowHeadline(response.showHeadline);
    }
    // eslint-disable-next-line
  }, [GetShowHeadline, auth0UserContext]);

  const extraCardComponent = useMemo(
    () => (
      <ExtraCardDiv
        hasImage={!!extraCardData.cardImgSrc}
        className={extraCardData.className ?? ""}
      >
        <>
          <AllBanksCardAtom
            profile={extraCardData.cardProlife}
            borderColor={extraCardData.cardBorderColor}
            data={extraCardData}
            dataElement={extraCardData.dataElement}
            dataProduct={extraCardData.dataProduct}
            isMaster={userContext?.isMaster}
          />
        </>
      </ExtraCardDiv>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extraCardData, userContext?.isMaster]
  );

  const dismissHeadLineStorage = () => {
    setDismissheadline(true);
    sessionStorage.setItem("dismiss-headline", "true");
  };

  return (
    <>
      {userContext?.isMaster === undefined ? (
        <LoadingContainer>
          <Spinner animation="border" />
          <LoadingText>{t("Loading") + "..."}</LoadingText>
        </LoadingContainer>
      ) : (
        <Container className="p-lg-0">
          <ContentWrapper>
            {showHeadline && !dismissHeadline && (
              <HeadLineDiv className="row">
                <div className="col mx-auto my-auto">
                  <CoxIcon
                    name="warning-no-filling"
                    fillColor="#EEA200"
                    width={20}
                    height={20}
                  />
                  <ManheimWarn>
                    <ManheimWarnBold>Manheim Avisa:</ManheimWarnBold> Você tem
                    uma retirada de veículo pendente.
                    <ManheimLink
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_C4U_PRODUTOS_URL}manheim-buyer-information?origin=headline`)
                      }
                    >
                      CLIQUE AQUI
                    </ManheimLink>
                    para visualizar
                  </ManheimWarn>
                </div>
                <ManheimDismiss
                  className="col-auto mx-auto my-auto"
                  onClick={() => dismissHeadLineStorage()}
                >
                  X
                </ManheimDismiss>
              </HeadLineDiv>
            )}

            <div>
              <Title>{t("WelcomeToCoxForYou")}</Title>
            </div>

            <Row>
              <Col md={12} lg={6}>
                <BigCardsDiv>
                  {bigCardsData.map((m, i) => (
                    <RegistrationHomeCardAtom
                      key={`big-card-${i}`}
                      profile={m.cardProlife}
                      borderColor={m.cardBorderColor}
                      data={m}
                      dataElement={m.dataElement}
                      dataProduct={m.dataProduct}
                    />
                  ))}
                  {!!showHeadline && (
                    <>
                      <div className="d-flex d-lg-none">
                        {extraCardComponent}
                      </div>
                    </>
                  )}
                </BigCardsDiv>
              </Col>

              <Col md={12} lg={6} className="d-none d-lg-flex">
                <FixedHeightDiv>
                  {isScrolledDown ? (
                    <TranslucidTopDiv
                      onClick={() => {
                        setIsScrolledDown(false);
                        scroll.scrollToTop(propsScroll);
                      }}
                    >
                      <Image src={CircleArrowUp} />
                    </TranslucidTopDiv>
                  ) : (
                    <TranslucidBottomDiv
                      onClick={() => {
                        setIsScrolledDown(true);
                        scroll.scrollToBottom(propsScroll);
                      }}
                    >
                      <Image src={CircleArrowDown} />
                    </TranslucidBottomDiv>
                  )}
                  <ButtonSrcollDiv id="button-scrollable-div">
                    {extraCardComponent}
                    {smallCardsContent}
                  </ButtonSrcollDiv>
                </FixedHeightDiv>
              </Col>

              <Col md={12} lg={6} className="d-none d-md-flex d-lg-none">
                {smallCardsContent}
              </Col>

              <Col md={12} lg={6} className="d-flex d-md-none mt-5">
                <Slider>
                  <Slides>
                    {/* <input type='radio' name='radio-btn' id='radio1' />
                  <input type='radio' name='radio-btn' id='radio2' />
                  <input type='radio' name='radio-btn' id='radio3' />
                  <input type='radio' name='radio-btn' id='radio4' />
                  <input type='radio' name='radio-btn' id='radio5' />
                  <input type='radio' name='radio-btn' id='radio6' /> */}
                    {smallCardsData.map((m, i) => (
                      <Slide
                        id={m.id}
                        className={i === 0 ? "first" : ""}
                        key={`small-card-slide-${i}`}
                      >
                        <RegistrationHomeSmallCardAtom
                          onClick={m.onClick}
                          data={m}
                          comingSoonText={t("ComingSoon")}
                          dataElement={m.dataElement}
                          dataProduct={m.dataProduct}
                          dataPosition={i + 1}
                        />
                      </Slide>
                    ))}
                  </Slides>
                </Slider>
              </Col>
              {/* <NavigationManual>
              <ManualBtn for='radio1' />
              <ManualBtn for='radio2' />
              <ManualBtn for='radio3' />
              <ManualBtn for='radio4' />
              <ManualBtn for='radio5' />
              <ManualBtn for='radio6' />
            </NavigationManual> */}
            </Row>
          </ContentWrapper>
        </Container>
      )}
    </>
  );
};

// const extraCardData = useMemo<ICpvHomeBigCardData>(
//   () => ({
//     cardProlife: "daycoval",
//     cardBorderColor: "#4A4A4A",
//     logoSrc: DaycovalLogoHome,
//     logoAltText: "Daycoval Logo",
//     cardText: t("DaycovalCardMessage"),
//     buttonText: t("RegisterDaycoval"),
//     onClick: () => {
//       window.location.href = process.env.REACT_APP_C4U_DT_URL ?? "";
//     },
//     buttonDisabled: !hasDealertrack,
//     buttonProfile: "daycoval",
//   }),
//   [hasDealertrack, t]
// );

// const extraCardData = useMemo<ICpvHomeBigCardData>(
//   () => ({
//     cardProlife: "rota21",
//     cardBorderColor: "#F3BC2A",
//     cardImgSrc: NaRotaDoGolCampaignLogo,
//     cardImgAltText: "Rota 21 Acessar Imagem",
//     cardTitle: t("Rota21CardTitle"),
//     cardText: (
//       <>
//         {t("Rota21CardMessagePart1")} <b>{t("Rota21CardMessagePart2")}</b>{" "}
//         {t("Rota21CardMessagePart3")}
//       </>
//     ),
//     buttonText: t("SeeRulesAndPrizes"),
//     onClick: () => {
//       window.location.href = RoutesHref ?? ""
//     },
//     buttonDisabled: false,
//     buttonProfile: "manheim",
//     dataElement: "product-card-primary",
//     dataProduct: "rota21",
//   }),
//   [RoutesHref, t]
// )

// const extraCardData = useMemo<ICpvHomeBigCardData>(
//   () => ({
//     cardProlife: "creditas",
//     cardBorderColor: "#38B076",
//     logoSrc: CreditasLogoHome,
//     logoAltText: "Creditas Logo",
//     cardText: [t("CreditasCardMessage1"), t("CreditasCardMessage2")],
//     buttonText: t("AccessCreditas"),
//     onClick: () => {
//       window.location.href = process.env.REACT_APP_C4U_DT_URL ?? "";
//     },
//     buttonDisabled: !hasDealertrack,
//     buttonProfile: "creditas",
//     dataElement: "product-card-primary",
//     dataProduct: "creditas",
//     className: "override-disabled-color",
//   }),
//   [hasDealertrack, t]
// );

// {
//   id: "card6",
//   onClick: () => {
//     window.location.href = process.env.REACT_APP_C4U_DT_URL ?? "";
//   },
//   backgroundColor: "#F2F2F3",
//   logoSrc: CreditasLogoVerticalHome,
//   logoAltText: "Creditas Logo",
//   title: t("Creditas"),
//   description: t("CreditasCardMessage1"),
//   descriptionLine2: t("CreditasCardMessage2"),
//   disabled: !hasDealertrack,
//   dataElement: "product-card-secondary",
//   dataProduct: "creditas",
// },
